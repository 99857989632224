import React from "react";
import MaillonsLayout from "../layouts/MaillonsLayout";
import MaillonsDetails from "../layouts/MaillonsDetails";
import MaillonsPhotos from "../layouts/MaillonsPhotos";
import Maillons from "../layouts/Maillons";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

const AuthRouter = () => {

  return (
    <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route exact path="/" component={MaillonsLayout} />
        <Route exact path="/annee/:annee" component={MaillonsLayout} />
        <Route exact path="/detail/:id" component={MaillonsDetails} />
        <Route exact path="/photos/:GalleryCode" component={MaillonsPhotos} />
        <Route exact path="/maillons" component={Maillons} />
      </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default AuthRouter;
