import React from "react";
import MainMenu from '../components/mainmenu';
import DetailPhoto from "../components/detailphoto";



export default function MaillonsDetails(props) {

  console.log("Photos: ", props);

  return (
  	<div id="wrapper">
      <MainMenu></MainMenu>
      <DetailPhoto GalleryCode={props.match.params.GalleryCode}></DetailPhoto>
    </div>
  );
}
