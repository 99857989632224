import React from 'react';

import { Link } from "@material-ui/core";
import RouterLink from '../routes/RouterLink'


export default function About() {


  return (
    <section className="blurb" id="about">
      <h2>Qui sommes nous?</h2>
      <p>Une association de bénévoles sensibles aux inégalités sociales. Nous voulons que la solidarité et l’entraide soient fondamentales parce que nous ne sommes pas tous égaux.</p>
      <h2>Notre philosophie<br/>Ensemble, nous sommes plus forts</h2>
      <p>Nous agissons dans un esprit de neutralité absolue, au regard des convictions religieuses, philosophiques ou politiques de chacun. Nos actions peuvent être d’ordre financier, juridique ou pratique.</p>
      <h2>Notre objectif</h2>
      <p>Apporter un soutien concret aux associations qui travaillent dans le domaine de l’aide aux personnes, prioritairement aux jeunes et aux handicapés, en organisant des activités culturelles, sportives et autres, pour tous, en vue de récolter des fonds.</p>
      <ul className="actions">
        <li><Link component={RouterLink} to="/maillons" ><span className="button">Savoir plus...</span></Link></li>
      </ul>
      <a href="mailto:info@lesmaillons.be" className="icon solid fa-envelope">&nbsp;Nous contacter</a>
    </section>);

}