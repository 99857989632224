import React from "react";
import MainMenu from '../components/mainmenu';
import DetailBody from '../components/detailbody';



export default function MaillonsDetails(props) {

  return (
  	<div id="wrapper">
      <MainMenu></MainMenu>
      <DetailBody id={props.match.params.id}></DetailBody>
    </div>
  );
}
