import { createTheme, makeStyles } from "@material-ui/core/styles";
import { blueGrey }  from "@material-ui/core/colors"

const colorBlue = "#123741";
const colorRed = "#620C12";
const primaryFont = "BauerBodoniBT";
const secondaryFont = "Open Sans";

export const GlobalTheme = createTheme({
  PrimaryFont: primaryFont,
  SecondaryFont: secondaryFont,
  ColorBlue: colorBlue,
  ColorRed: colorRed,
  LightGrey: "#FAFAFA",
  ActiveColor: "#EEE",
  BackgroundColor: "white",
  LightLineWidth: "1px",
  typography: {
    fontFamily: "Open Sans"
  },
  palette: {
    primary: blueGrey,
  },
  overrides: {
    MuiButton: {
        root: {
            borderRadius: 0,
            border: "1px solid #DDD",
            textTransform: "none",
            textDecoration: "none",
            textAlign: "center",
          }
    }
  }
});

const GlobalStylesAppend = (newStyle,theme) => ({
  NSecHeader: {
    fontFamily: theme.PrimaryFont,
    fontSize: "200%",
    textDecoration: "none",
    color: theme.ActiveColor
  },
  NDropBox: {
    padding: "20px",
    border: "1px solid #DDD",
    lineHeight: "110%",
    color: theme.ActiveColor
  },
  NTextField: {
    color: theme.ActiveColor
  },
  NTextFieldInput: {
    color: theme.ActiveColor
  },
  NTextFieldInputHuge: {
    fontSize: "150%",
    color: theme.ActiveColor
  },
  NButton: {
    color: theme.ActiveColor,
    borderWidth: theme.LightLineWidth,
    borderColor: theme.ActiveColor,
    borderStyle: "plain",
    margin: "1px",
    fontFamily: secondaryFont
  },
  NTable: {
  },
  NPaperWidget: {
    padding: "10px",
  },
  Debug: {
    backgroundColor: "red"
  },
  NHeight70 : {
    height: "70vh",
  },
  ...newStyle(theme)
});



export const makeStylesGlobal = newStyle => makeStyles(theme => GlobalStylesAppend(newStyle,theme));

