import React, { useEffect, useState } from 'react';
import Axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "@material-ui/core";
import RouterLink from '../routes/RouterLink'
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';


const URL = "/api/Events/"


export default function DetailBody(props) {

  const [items, setItems] = useState();
  const [loading, setLoading] = useState(true);
  const [contactVisible, setContactVisible] = useState(false);

  const showContact = () => { setContactVisible(true) }


  useEffect(() => {
    const fetchData = async () => {
      await
        new Promise((resolve, reject) => {

          var config = {
          };
          Axios.get(URL + props.id, config)
            .then(function (response) {
              setItems(response.data);
              console.log("Loading: ",response.data);
              setLoading(false);
            })
            .catch(function (error) {
              console.log(error);
            });
        });
    };
    fetchData();
  }, [props.id, props.GalleryCode]);





  if (loading) {
    return <CircularProgress />
  }



  return (
    <div id="wrapper">


      <div id="main">

        <Button className="icon solid fa-home">&nbsp;<Link component={RouterLink} to="/">Page principale</Link></Button><p>&nbsp;</p>


        {items && items.map((item) =>

          <article key={item.ID} className="post">
            <header>
              <div className="title">
                <h2>{item.Title}</h2>
                <p>{item.SubTitle}</p>
                <p>{item.Category}</p>
              </div>
              <div className="meta">
                <time className="published" dateTime={item.EventDate}>{item.DisplayDate}</time>
                <span className="name">{item.Organiser}</span>
                {item.AssociationLink !== "" ?
                  <span className="name"><a target="_blank" rel="noopener noreferrer" href={item.AssociationLink}>{item.Association}</a></span>
                  : <span className="name">{item.Association}</span>
                }
              </div>
            </header>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                {item.Img &&
                  <span className="image featured"><img src={"/api/media/" + item.Img} alt={item.Img} /></span>
                }
              </Grid>
              <Grid item xs={8}>

                <Grid container spacing={3}>
                {item.ShortDescription &&
                    <React.Fragment>
                      <Grid item xs={1}><br /></Grid>

                      <Grid item xs={11}><div dangerouslySetInnerHTML={{ __html: item.ShortDescription }} /></Grid>


                    </React.Fragment>

                  }
                  
                  <Grid item xs={1}><h2 className="icon solid fa-hand-holding-heart fa-lg" style={{ textAlign: "center", verticalalign: "middle" }}>&nbsp;</h2></Grid>
                  <Grid item xs={11}><h2><font color="gray">En soutien à:</font>&nbsp;<a target="_blank" rel="noopener noreferrer" href={item.AssociationLink}>{item.Association}</a></h2></Grid>

                  <Grid item xs={1}><h2 className="icon regular fa-calendar-alt fa-lg" style={{ textAlign: "center" }}>&nbsp;</h2></Grid>
                  <Grid item xs={11}><h2><font color="gray">Date:</font>&nbsp;{item.DisplayDate}</h2></Grid>

                  {item.Phone &&
                    <React.Fragment>
                      <Grid item xs={1}><h2 className="icon solid fa-phone-square-alt fa-lg" style={{ textAlign: "center" }}>&nbsp;</h2></Grid>
                      <Grid item xs={11}><h2><font color="gray">Renseignements:&nbsp;</font><a href={"tel:" + item.Phone}>{item.Phone}</a></h2></Grid>
                    </React.Fragment>
                  }

                  {item.PDF &&
                    <React.Fragment>
                      <Grid item xs={1}><h2 className="icon solid fa-file-pdf fa-lg" style={{ textAlign: "center" }}>&nbsp;</h2></Grid>
                      <Grid item xs={11}><h2><a target="_blank" rel="noopener noreferrer" href={"/api/media/" + item.PDF}>Télécharger la brochure en PDF</a></h2></Grid>
                    </React.Fragment>
                  }

                  {item.Mail &&
                    <React.Fragment>
                      <Grid item xs={1}><br /><h2 className="icon regular fa-envelope fa-lg" style={{ textAlign: "center", verticalalign: "middle" }}>&nbsp;</h2></Grid>
                      {contactVisible ?
                        <Grid item xs={11}><h2><font color="gray">Contact:&nbsp;</font><a href={"mailto:" + item.Mail}>{item.Mail}</a></h2></Grid>
                        :
                        <Grid item xs={11}><h2><Button color="secondary" size="large" onClick={showContact}>Nous contacter...</Button></h2></Grid>
                      }

                    </React.Fragment>
                  }
                  {item.hasGallery &&
                  <React.Fragment>
                      <Grid item xs={1}><h2 className="icon solid fa-photo-video" style={{ textAlign: "center" }}>&nbsp;</h2></Grid>
                      <Grid item xs={11}><h2>
                      <a href={`https://photos.app.goo.gl/${item.GalleryCode}`} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                      Galerie d'image...
                       </a>
                        </h2></Grid>
                  </React.Fragment>
              
              }

                 



                </Grid>

              </Grid>

            </Grid>

            <footer>
              <p>{item.Detail}</p>

            </footer>
          </article>

        )}


      </div>





    </div>

  );

}


