import React from 'react';
import { Link } from "@material-ui/core";
import RouterLink from '../routes/RouterLink'
import { Button } from '@material-ui/core';
import ReactPlayer from 'react-player'


export default function MainMaillons() {




    return (
        <div id="wrapper">


            <div id="main">
            <Button className="icon solid fa-home">&nbsp;<Link component={RouterLink} to="/">Page principale</Link></Button><p>&nbsp;</p>
      

                <article className="post">
                    <header>
                        <div className="title">
                        <h1><font color="gray">Les maillons</font> de la solidarité</h1>
                        <br></br>
                        <p>Les Maillons de la Solidarité ASBL – N°&nbsp;d’entreprise 0838 728 217<br/>Siège social : Rue du pont des Ardennes, 4&nbsp;Bte&nbsp;13 – 5100 Jambes</p>
                        </div>
                        <div className="meta">
                           <img src="/images/maillons-small.jpg" alt="Les maillons"></img>
                        </div>
                    </header>
                    <ReactPlayer url="https://youtu.be/Y0BqJuYdMN0" volume="0"  playing="true" loop="true"  />
                    <h1>Qui sommes nous?</h1>
      <p>Une association de bénévoles sensibles aux inégalités sociales. Nous voulons que la solidarité et l’entraide soient fondamentales parce que nous ne sommes pas tous égaux.</p>
      <h1>Notre philosophie<br/>Ensemble, nous sommes plus forts</h1>
      <p>Nous agissons dans un esprit de neutralité absolue, au regard des convictions religieuses, philosophiques ou politiques de chacun. Nos actions peuvent être d’ordre financier, juridique ou pratique.</p>
      <h1>Notre objectif</h1>
      <p>Apporter un soutien concret aux associations qui travaillent dans le domaine de l’aide aux personnes, prioritairement aux jeunes et aux handicapés, en organisant des activités culturelles, sportives et autres, pour tous, en vue de récolter des fonds.</p>
   
                     <h1>Notre structure</h1>
                    <h3>Le conseil d'administration</h3>
                    <ul>
                        <li><b>Présidente:</b> Florence Bienfait</li>
                        <li><b>Vice-Présidente:</b> Catelyne Papa</li>
                        <li><b>Secrétaire:</b> Eric Mahy</li>
                        <li><b>Trésorier:</b> Claude Specen-Berry</li>
                    </ul>
                    <h3>Les commissions</h3>
                    <ul>
                        <li><b>Aide aux études:</b> Michèle Nihoul et Eric Mahy</li>
                        <li><b>Evénements:</b> Henri-François Van Aelst</li>
                        <li><b>Communication:</b> Claude Specen-Berry</li>
                        <li><b>Vins:</b> Roberto Galante et Claude Specen-Berry</li>
                        <li><b>Inventaire:</b> Michel Allard et Laurent Gruslin</li>
                    </ul>

                    <h1>NOUS CONTACTER</h1>
                    <p>e-mail: <a href="mailto:info@lesmaillons.be">info@lesmaillons.be</a></p>
                    <h3>IBAN : BE58 0688 9357 8979 – BIC GKCCBEBB</h3>
                    <footer>
                        <br/>
                        <img src="/images/ChaineHumaine.png" alt="chaine humaine"></img>
                    </footer>
                </article>

            </div>


        </div>

    );


}


