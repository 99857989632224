import React, { useEffect, useState } from 'react';
import Sidebar from './sidebar';
import Axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "@material-ui/core";
import RouterLink from '../routes/RouterLink'
import { Button } from '@material-ui/core';
import ImageGallery from 'react-image-gallery';
import Grid from '@material-ui/core/Grid';


// Rallye https://photos.app.goo.gl/CHGiEjswhybcf35m6
// Bikers https://photos.app.goo.gl/mAfdNwnpmMfLScyo9
// Balade https://photos.app.goo.gl/c36s6H6cRPFhjVP18


export default function DetailPhoto(props) {

  const [images, setImages] = useState();
  const [loading, setLoading] = useState(true);
  const [webpageContent, setWebpageContent] = useState('');
  
  const regex = /\["(https:\/\/lh3\.googleusercontent\.com\/[a-zA-Z0-9\-_]*)"/g

  function extractPhotos(content) {

    var enc = new TextDecoder("utf-8");
    var decode = enc.decode(content);

    console.log(decode);
    const links = new Set()
    let match
    while (match = regex.exec(decode)) {
      links.add(match[1])
    }
    return Array.from(links)
  }

  async function getAlbum(id) {

    const response = await Axios.get(`/api/photos/${id}`);
    return response.data;
    
  }


  useEffect(() => {
    const fetchData = async () => {
      await
        new Promise((resolve, reject) => {

          setLoading(true);

       
          getAlbum(props.GalleryCode).then(
            function (response) {

              if (response && response.length > 0) {
                setImages(response.map(url => ({
                  original: `${url}=w1024`,
                  thumbnail: `${url}=w100`
                })));
                setLoading(false);
              }
            
            }).catch(function (error) {
              console.log(error);
            });
            
        });
    };
    fetchData();
  }, [props.id]);


  if (loading) {
    return <CircularProgress/>
  }


  return (
    <div id="wrapper">
      <div id="main">
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Button className="icon solid fa-home">&nbsp;<Link component={RouterLink} to="/">Page principale</Link></Button><p>&nbsp;</p>
            {images ?
              <ImageGallery items={images} thumbnailPosition="top" />
              :
              <p>Pas d'image disponible...</p>
            }
          </Grid>
        </Grid>
      </div>
    </div>
  );


}
