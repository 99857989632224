import React, { useEffect, useState } from 'react';

import About from './about';
import { Link } from "@material-ui/core";
import RouterLink from '../routes/RouterLink';
import Axios from "axios";



export default function Sidebar() {


  const URL = "/api/beneficiaires"
 
  const [items, setItems] = useState();
  


  useEffect(() => {
    const fetchData = async () => {
   
       await 
        new Promise((resolve, reject) => {

          var config = {
          };
          Axios.get(URL, config)
            .then(function(response) {
              setItems(response.data);
            })
            .catch(function(error) {
              console.log(error);
            });
        });
       };
       fetchData();
      },[]);


  return (
    <section id="sidebar">

      {/*  Intro */}
      <section id="intro">
        <Link component={RouterLink} to="/" className="logo"><img src="images/maillons-logo.jpg" alt="" /></Link>
      </section>

      <About></About>


      {/*  Mini Posts */}
      <section>
        <h2>les bénéficiaires</h2>
        <div className="mini-posts">


        {items && items.map((item) =>
          <article key={item.ID}  className="mini-post">
          <header>
            <h2><a target="_blank" rel="noopener noreferrer" href={item.URL}>{item.Name}</a></h2>
          </header>
          <a target="_blank" rel="noopener noreferrer"  href={item.URL} className="image"><img src={"/api/media/"+item.image} alt="" /></a>
         </article>
        )}

        </div>
      </section>

      
      {/*  Footer */}
      <section id="footer">
        <a href="https://www.facebook.com/Les-Maillons-de-la-Solidarité-ASBL-690436547697277/">Vie privée</a>
        <ul className="icons">
          <li><a href="https://www.facebook.com/Les-Maillons-de-la-Solidarité-ASBL-690436547697277/" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
          <li><a href="mailto:info@lesmaillons.be" className="icon solid fa-envelope"><span className="label">Email</span></a></li>
        </ul>
        <p className="copyright">&copy; 2020 les maillons de la solidarité. Crédits: <a href="http://html5up.net">HTML5 UP</a>.</p>
      </section>

    </section>);

}

// https://youtu.be/Y0BqJuYdMN0