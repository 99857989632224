import React, {useState} from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import { Link } from "@material-ui/core";
import RouterLink from '../routes/RouterLink'


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    background: '#FFFFFF',
    color: '#000000',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  view: {
    position: "absolute",
    right: "0",
    marginRight: "10px",
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

export default function PersistentDrawerRight() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <header id="header">
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>

            <h1><Link component={RouterLink} to="/" ><font color="gray">Les maillons</font> de la solidarité</Link></h1>
            <nav className="links">
              <ul>
                <li><Link component={RouterLink} to="/maillons" >Qui sommes-nous?</Link></li>
                <li>Nos réalisations:</li>
                <li><Link component={RouterLink} to="/annee/2024" >2024</Link></li>
                <li><Link component={RouterLink} to="/annee/2023" >2023</Link></li>
                <li><Link component={RouterLink} to="/annee/2022" >2022</Link></li>
                <li><Link component={RouterLink} to="/annee/2021" >2021</Link></li>
                <li><Link component={RouterLink} to="/annee/2020" >2020</Link></li>
                <li><Link component={RouterLink} to="/annee/2019" >2019</Link></li>
                <li><Link component={RouterLink} to="/annee/2018" >2018</Link></li>
                <li><Link component={RouterLink} to="/annee/0" >avant...</Link></li>
              </ul>
            </nav>
            {!open &&
            <IconButton
            //  color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={classes.view}
            >
              <MenuIcon />
            </IconButton>
            }
          </Toolbar>
        </AppBar>
      </header>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />

      </main>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton className={classes.view} onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button><Link component={RouterLink} onClick={() => setOpen(false)} to="/maillons" >Qui sommes-nous?</Link>        </ListItem>
          <ListItem button><Link component={RouterLink} onClick={() => setOpen(false)} to="/annee/2024" >Nos réalisations en 2024</Link>        </ListItem>
          <ListItem button><Link component={RouterLink} onClick={() => setOpen(false)} to="/annee/2023" >Nos réalisations en 2023</Link>        </ListItem>
          <ListItem button><Link component={RouterLink} onClick={() => setOpen(false)} to="/annee/2022" >Nos réalisations en 2022</Link>        </ListItem>
          <ListItem button><Link component={RouterLink} onClick={() => setOpen(false)} to="/annee/2021" >Nos réalisations en 2021</Link>        </ListItem>
          <ListItem button><Link component={RouterLink} onClick={() => setOpen(false)} to="/annee/2020" >Nos réalisations en 2020</Link>        </ListItem>
          <ListItem button><Link component={RouterLink} onClick={() => setOpen(false)} to="/annee/2019" >Nos réalisations en 2019</Link>        </ListItem>
          <ListItem button><Link component={RouterLink} onClick={() => setOpen(false)} to="/annee/2018" >Nos réalisations en 2018</Link>        </ListItem>
          <ListItem button><Link component={RouterLink} onClick={() => setOpen(false)} to="/annee/0" >Nos réalisations avant</Link>        </ListItem>
        </List>

      </Drawer>
    </div>
  );
}