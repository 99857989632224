import React from "react";
import MainMenu from '../components/mainmenu';
import MainMaillons from '../components/mainmaillons';




export default function Maillons() {
 
  return (
  	<div id="wrapper">
      <MainMenu></MainMenu>
      <MainMaillons></MainMaillons>
    </div>
  );
}
