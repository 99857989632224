import React from 'react';
import MainMenu from '../components/mainmenu';
import MainBody from '../components/mainbody';


export default function MaillonsLayout(props) {
    
  return (
  	<div id="wrapper">
      <MainMenu></MainMenu>
      <MainBody annee={props.match.params.annee}></MainBody>
    </div>
  );
}
